<template>
  <div class="right-main">
    <p class="title-text">题库预览</p>
    <div class="search-content flex flex-col-center flex-row-between">
      <el-input class="input-text" v-model="searchText" placeholder="请输入关键字查询" clearable @input="searchChange"></el-input>
      <i class="el-icon-search" @click="searchClick"></i>
    </div>
    <div class="scro-title-content" id="jc-title">
      <div class="flex flex-col-center flex-row-between">
        <p class="scro-title">基础题库</p>
        <div class="scro-line"></div>
      </div>
      <div class="tip-text">最近更新：2024年2月22日，本部分内容取自企业工商信息模型，创建调研问卷自动选取本题库所有内容，用户填写时可自动获取工商数</div>
    </div>
    <question-type :topicData="rightListData[0].cateList"></question-type>
    <div class="scro-title-content" id="gg-title">
      <div class="flex flex-col-center flex-row-between">
        <p class="scro-title">公共题库</p>
        <div class="scro-line"></div>
      </div>
      <div class="tip-text">最近更新：2024年2月22日，创建调研问卷时可自主选择本题库的部分问题，并设置填写验证。</div>
    </div>
    <question-type :topicData="rightListData[1].cateList"></question-type>
  </div>
</template>

<script>
import questionType from "@/views/questionBank/components/question-type";

export default {
  name: 'right-item',
  components: {questionType},
  props: {
    rightListData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchText: ''
    }
  },
  mounted() {

  },
  methods: {
    initData() {
      this.$api.questionnaire_basic_bank({title: this.searchText}).then(res => {
        this.$emit('update:rightListData', res.data);
      });
    },
    // 搜索值改变为空值后
    searchChange() {
      if (this.searchText === '') {
        this.initData();
      }
    },
    // 搜索点击
    searchClick() {
      this.initData();
    },
    //定位
    scrollToElement(selector) {
      let element = document.querySelector(selector);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.right-main {flex: 1;margin-left: 18px;background-color: #fff;height: 100%;overflow: scroll;scrollbar-width: none;-ms-overflow-style: none;padding: 30px;box-sizing: border-box;
  .title-text {font-size: 24px;color: #242D33;}
  .search-content {width: 560px;height: 44px;background: #F6F7F9;border-radius: 22px;margin-top: 30px;padding: 0 20px;box-sizing: border-box;
    ::v-deep .el-input {
      .el-input__inner {padding: 0 30px 0 0;border: 0;background-color: transparent;font-size: 14px;color: #242D33;}
      .el-input__clear {font-size: 16px;}
    }
    i {font-size: 18px;color: #A7B2B8;
      &:hover {cursor: pointer;color: #3984F5;}
    }
  }
  .scro-title-content {margin-top: 40px;
    .scro-title {font-size: 18px;color: #242D33;position: relative;}
    .scro-title::before {position: absolute;content: '';left: -30px;top: 3px;width: 4px;height: 20px;background: #3984F5;}
    .scro-line {flex: 1;height: 1px;background: #EDF1F4;margin-left: 9px;}
  }
  .tip-text {font-size: 12px;color: #909DA5;margin-top: 24px;}
}
</style>

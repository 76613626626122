<template>
  <div class="question-main flex">
    <left-item @maxJumpEvent="maxJumpClick" @jumpEvent="jumpClick" :leftListData="allData"></left-item>
    <right-item ref="rightContent" :rightListData.sync="allData"></right-item>
  </div>
</template>

<script>
import leftItem from "./components/left-item.vue"
import rightItem from "./components/right-item.vue"

export default {
  components: {
    leftItem, rightItem
  },
  data() {
    return {
      allData: [
        {
          title: ''
        },
        {
          title: ''
        }
      ]
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      // this.isLoading = true;
      this.$api.questionnaire_basic_bank().then(res => {
        // this.isLoading = false;
        this.allData = res.data;
      }).catch(err => {
        // this.isLoading = false;
      })
    },
    //跳转大类型
    maxJumpClick(e) {
      if (e === '0') this.$refs.rightContent.scrollToElement('#jc-title');
      if (e === '1') this.$refs.rightContent.scrollToElement('#gg-title');
    },
    //跳转小类型
    jumpClick(e) {
      this.$refs.rightContent.scrollToElement('#' + e);
    }
  }
}
</script>

<style lang="scss" scoped>
.question-main {width: 100%;height: 100%;padding: 18px;box-sizing: border-box;

}
</style>

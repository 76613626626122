<template>
  <div>
    <div v-for="(items, index) in topicData" :key="items.id">
      <div class="type-name" :id="'title-' + items.id">{{ numberToChinese(index + 1) }}、{{ items.title }}</div>
      <div class="topic-list-content" v-for="(item, index) in items.queList" :key="item.id">
        <div class="name-content flex flex-col-center">
          <span class="type-text" :style="[getTitleColor(item.type)]">
            {{item.type === 0 ? '单项选择' : item.type === 1 ? '多项选择' : item.type === 2 ? '单项填空' : item.type === 3 ? '多项填空' : item.type === 4 ? '单选填空' : item.type === 5 ? '详细地址' : item.type === 6 ? '日期选择' : ''}}
          </span>
          <span class="topic-title">{{ index + 1 }}. {{ item.title }}{{ item.require === 1 ? '（必填）' : '' }}</span>
        </div>
        <div class="" v-if="item.type===0"><!--单项选择-->
          <el-radio-group v-model="radio[index]">
            <el-radio :label="items.title" v-for="(items) in item.options" :key="items.id">{{ items.title }}</el-radio>
          </el-radio-group>
        </div>
        <div class="checkbox-content" v-if="item.type===1"><!--多项选择-->
          <el-checkbox-group class="checkbox_flex" v-model="checkbox">
            <el-checkbox :label="items.title" v-for="(items) in item.options" :key="items.id">{{items.title}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="" v-if="item.type===2 || item.type===3" style="margin-top: 18px;"><!--单项填空、多项填空-->
          <div v-if="item.options.length">
            <div class="flex flex-col-center" v-for="(items) in item.options" :key="items.id" style="margin-top: 10px;">
              <input class="input-text" type="text" :placeholder="items.title"/>
              <!--<span class="unit-text" v-if="items.unit">{{items.unit}}</span>-->
            </div>
          </div>
          <div v-else>
            <input class="input-text" type="text"/>
          </div>
        </div>
        <div class="" v-if="item.type===4"><!--单选填空-->
          <el-radio-group v-model="radioInput[index]">
            <el-radio :label="items.order" v-for="(items) in item.options" :key="items.id">{{ items.title }}</el-radio>
          </el-radio-group>
          <input v-if="radioInput[index] === 2" class="input-text" type="text" style="margin-top: 10px"/>
        </div>
        <div class="address-content flex flex-col-center" v-if="item.type===5"><!--详细地址-->
          <el-cascader v-model="addressValue[index]" :options="options" placeholder="请选择省市区"></el-cascader>
          <input class="address-input" type="text" placeholder="请填写详细地址"/>
        </div>
        <div class="date-content" v-if="item.type===6"><!--日期选择-->
          <el-date-picker v-model="dateText[index]" type="date" placeholder="年/月/日"></el-date-picker>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import address from "../../questionnaire/components/address.js"
export default {
  name: 'questionType',
  props: {
    topicData: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.options = address;
  },
  data() {
    return {
      radio: [],//单项选择
      checkbox: [],//多项选择
      radioInput: [],//单选填空
      options: [],//地区数据
      addressValue: [],//地区选择的数据
      dateText: [],//日期选择
    }
  },
  methods: {
    //标题颜色
    getTitleColor(type) {
      let style = {border: '1px solid #3984F5', color: '#3984F5'};
      switch (type) {
        case 0:
          style = {border: '1px solid #7065FF', color: '#7065FF'};
          break;
        case 1:
          style = {border: '1px solid #609DF7', color: '#609DF7'};
          break;
        case 2:
          style = {border: '1px solid #3984F5', color: '#3984F5'};
          break;
        case 3:
          style = {border: '1px solid #1C72F1', color: '#1C72F1'};
          break;
        case 4:
          style = {border: '1px solid #FB991A', color: '#FB991A'};
          break;
        case 5:
          style = {border: '1px solid #9C2DFA', color: '#9C2DFA'};
          break;
        case 6:
          style = {border: '1px solid #04CD83', color: '#04CD83'};
          break;
        default:
          break;
      }
      return style;
    },
    // 数字转为汉字
    numberToChinese(num) {
      let arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
      let arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿');//可继续追加更高位转换值
      if (!num || isNaN(num)) {
        return "零";
      }
      let english = num.toString().split("")
      let result = "";
      for (let i = 0; i < english.length; i++) {
        let des_i = english.length - 1 - i;//倒序排列设值
        result = arr2[i] + result;
        let arr1_index = english[des_i];
        result = arr1[arr1_index] + result;
      }
      //将【零千、零百】换成【零】 【十零】换成【十】
      result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
      //合并中间多个零为一个零
      result = result.replace(/零+/g, '零');
      //将【零亿】换成【亿】【零万】换成【万】
      result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
      //将【亿万】换成【亿】
      result = result.replace(/亿万/g, '亿');
      //移除末尾的零
      result = result.replace(/零+$/, '')
      //将【零一十】换成【零十】
      //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
      //将【一十】换成【十】
      result = result.replace(/^一十/g, '十')
      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
.type-name {margin-top: 20px;font-size: 14px;color: #242D33;}
.topic-list-content {
  .name-content {margin-top: 30px;
    .type-text {width: 64px;height: 24px;background: #FFFFFF;font-size: 12px;text-align: center;line-height: 24px;}
    .topic-title {font-size: 14px;color: #242D33;margin-left: 11px;}
  }
  .input-text {width: 480px;height: 34px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;line-height: 34px;padding: 0 12px;box-sizing: border-box;}
  .unit-text {font-size: 12px;color: #242D33;margin-left: 10px;line-height: 34px;}
  .el-radio-group {display: flex;flex-direction: column;align-items: flex-start;
    .el-radio {margin-top: 18px;}
  }
  .checkbox-content {
    .checkbox_flex {display: flex;flex-direction: column;
      .el-checkbox {margin-top: 16px;}
    }
  }
  .address-content {margin-top: 18px;
    .el-cascader {width: 280px;}
    .address-input {width: 360px;height: 40px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;padding: 0 12px;margin-left: 10px;box-sizing: border-box;}
  }
  .date-content {margin-top: 18px;
    .el-input {width: 360px;}
  }
}
</style>

<template>
  <div class="left-main">
    <div class="tk-btn-content flex flex-col-center" @click="maxTypeClick('0')">
      <img class="btn-img" src="@/assets/sendQuestionnaire/bank_base_icon.png" alt=""/>
      <div class="text-content">
        <p class="text-title">{{ leftListData[0].title }}</p>
        <p class="con-text">工商信息方向，共 {{ leftListData[0].num }} 题</p>
      </div>
    </div>
    <div class="topic-list-content flex flex-col-center flex-row-between" v-for="(item, index) in leftListData[0].cateList" :key="item.id" @click="typeClick('title-' + item.id)">
      <p class="title-text">{{ numberToChinese(index + 1) }}、{{ item.title }}</p>
      <p class="num-text">共{{ item.num }}题</p>
    </div>
    <div class="tk-btn-content flex flex-col-center" @click="maxTypeClick('1')">
      <img class="btn-img" src="@/assets/sendQuestionnaire/bank_public_icon.png" alt=""/>
      <div class="text-content">
        <p class="text-title">{{ leftListData[1].title }}</p>
        <p class="con-text">企业经营方向，共 {{ leftListData[1].num }} 题</p>
      </div>
    </div>
    <div class="topic-list-content flex flex-col-center flex-row-between" v-for="(item, index) in leftListData[1].cateList" :key="item.id" @click="typeClick('title-' + item.id)">
      <p class="title-text">{{ numberToChinese(index + 1) }}、{{ item.title }}</p>
      <p class="num-text">共{{ item.num }}题</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'left-item',
  props: {
    leftListData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  },
  methods: {
    //大类型跳转
    maxTypeClick(e) {
      this.$emit('maxJumpEvent', e);
    },
    //小类型跳转
    typeClick(e) {
      this.$emit('jumpEvent', e);
    },
    // 数字转为汉字
    numberToChinese(num) {
      let arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
      let arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿');//可继续追加更高位转换值
      if (!num || isNaN(num)) {
        return "零";
      }
      let english = num.toString().split("")
      let result = "";
      for (let i = 0; i < english.length; i++) {
        let des_i = english.length - 1 - i;//倒序排列设值
        result = arr2[i] + result;
        let arr1_index = english[des_i];
        result = arr1[arr1_index] + result;
      }
      //将【零千、零百】换成【零】 【十零】换成【十】
      result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
      //合并中间多个零为一个零
      result = result.replace(/零+/g, '零');
      //将【零亿】换成【亿】【零万】换成【万】
      result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
      //将【亿万】换成【亿】
      result = result.replace(/亿万/g, '亿');
      //移除末尾的零
      result = result.replace(/零+$/, '')
      //将【零一十】换成【零十】
      //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
      //将【一十】换成【十】
      result = result.replace(/^一十/g, '十')
      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
.left-main{width: 300px;height: 100%;background: #fff; overflow: scroll;scrollbar-width: none;-ms-overflow-style: none;
  &::webkit-scrollbar{display: none;}
  .tk-btn-content{padding: 20px 18px;border-bottom: 1px solid #EDF1F4;
    .btn-img{width: 36px;height: 36px;}
    .text-content{margin-left: 11px;
      .text-title{font-size: 14px;color: #242D33;font-weight: bold;}
      .con-text{font-size: 12px;color: #909DA5;margin-top: 2px;}
    }
    &:hover{cursor: pointer;background-color: #f5f5f5;}
  }
  .topic-list-content{height: 69px;padding: 0 18px;border-bottom: 1px solid #EDF1F4;
    .title-text{font-size: 12px;font-weight: bold;color: #242D33;}
    .num-text{font-size: 12px;color: #909DA5;}
    &:hover{cursor: pointer;background-color: #f5f5f5;}
  }
}
</style>
